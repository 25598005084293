body {
    padding-top: 60px;
    margin: 0;
}

*{
    -ms-overflow-style: none;
}
::-webkit-scrollbar {
    display: none;
}